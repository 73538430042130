.realE_banner {
  height: 100vh;
  background: url("../../../assets/img/realEstate//Hero\ V9\ \(1\).png")
    no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p:nth-child(1) {
    text-align: center;
    color: white;
    font-size: 64px;
    font-weight: 800;
    width: 1200px;
    line-height: 130%;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      width: 90%;
      font-size: 36px;
    }
  }
  p:nth-child(2) {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    width: 1200px;
    line-height: 150%;
    @media (max-width: 768px) {
      width: 90%;
      font-size: 16px;
    }
  }
}
