.rental_box {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  color: white;

  img {
    width: 100%;
    height: 100vh;
    display: block;
  }

  .rental_title_box {
    position: absolute;
    top: 50%;
    left: 28%;
    transform: translate(-50%, -50%);
    text-align: left;
    width: 570px;
    @media (max-width: 768px) {
      width: 90%;
      left: 50%;
      text-align: center;
    }
  }
  div:nth-child(1) {
    font-size: 112px;
    font-weight: 800;
    line-height: 130%;
    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
  div:nth-child(2) {
    font-size: 32px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  div:nth-child(3) {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}

@media (max-width: 768px) {
  .rental_box {
    height: 90vh;
    img {
      object-fit: cover;
      object-position: bottom;
    }
  }
}
