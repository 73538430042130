.header_box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  width: 1200px;

  @media (max-width: 1520px) {
    width: 98%;
  }

  .right_logo {
    width: 117.771px;
    height: 36px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .right_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #fff;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;
      margin-left: 48px;
      cursor: pointer;
    }
  }
}
.ant-popover-inner {
  background-color: black !important;
  .ant-popover-inner-content {
    color: #fff;
    .sec_page {
      padding: 16x 24px;
      p {
        padding: 12px 8px;
        cursor: pointer;
      }
    }
  }
}
