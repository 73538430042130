.service-section {
  display: flex;
  padding-top: 80px;
  padding-bottom: 80px;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: unset;
    display: unset;
  }

  .text-content {
    flex: 1;
    order: 2;
    margin-left: 64px;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
      margin-left: unset;
    }

    p:nth-child(1) {
      font-size: 28px;
      font-weight: 600;
      line-height: 130%;
      margin-bottom: 32px;
      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
    p:nth-child(2) {
      color: #525252;
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
    }
    p:nth-child(4) {
      color: #525252;
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .image-content {
    order: 1;
    width: 50%;
    height: 320px;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 32px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.image-right {
    .text-content {
      order: 1;
      margin-right: 64px;
      margin-left: 0;
    }

    .image-content {
      order: 2;
    }
  }
}
