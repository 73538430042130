.autoCar_banner {
  height: 100vh;
  background: url("../../../assets/img/autoCar/banner.png") no-repeat center
    center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    text-align: center;
    color: white;
    font-size: 112px;
    font-weight: 800;
    width: 1200px;
    line-height: 130%;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 40px;
      width: 90%;
    }
  }
}
.autoCar_content {
  width: 1200px;
  padding-top: 80px;
  padding-bottom: 80px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .title_box {
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      font-size: 40px;
      text-align: left;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .icon_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    div {
      display: flex;
      flex-direction: column;
      padding: 12px;
      width: 33.3%;
      align-items: center;

      img {
        width: 56px;
        height: 56px;
        margin-bottom: 16px;
      }
      p {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-transform: capitalize;
      }
    }
    .line_box {
      border-left: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;
      @media (max-width: 768px) {
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        border-left: unset;
        border-right: unset;
      }
    }
  }
  .img_box {
    display: flex;
    gap: 24px;
    width: 380px;
    height: 440px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
}
.profess_box {
  background-color: #000;
  padding-top: 80px;
  color: #fff;
  @media (max-width: 768px) {
    padding: 40px 20px;
  }

  .text_box {
    width: 1200px;
    text-align: center;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 100%;
      text-align: left;
    }
    p:nth-child(1) {
      font-size: 48px;
      font-weight: 700;
      line-height: 150%;
      margin-bottom: 24px;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 40px;
      }
    }
    p:nth-child(2) {
      font-size: 18px;
      font-weight: 400;
      line-height: 160%;
      margin-bottom: 80px;
      @media (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 60px;
      }
    }
  }
  .common_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    gap: 130px;
    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      gap: 40px;
    }
    .common_left {
      width: 100%;
      height: 630px;
      @media (max-width: 768px) {
        height: 449px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .common_right {
      p {
        margin-bottom: 24px;
      }
      p:nth-child(1) {
        font-size: 48px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 40px;
        }
      }
      p:nth-child(2) {
        font-size: 18px;
        font-weight: 400;
        line-height: 160%;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      .rent-button {
        background-color: #ff6a00;
        color: #fff;
        border: none;
        margin-top: 16px;
        width: 173px;
        padding: 16px;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        transition: background-color 0.3s ease;
        border-radius: 4px;

        .button-arrow {
          display: inline-block;
          margin-left: 8px;
        }
      }
    }
  }
}
