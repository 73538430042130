.project_banner {
  height: 100vh;
  background: url("../../../assets/img/ourProject/home.png") no-repeat center
    center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p:nth-child(1) {
    color: white;
    text-transform: uppercase;
    font-size: 112px;
    font-weight: 800;
    line-height: 130%;
    @media (max-width: 768px) {
      font-size: 48px;
      margin-bottom: 8px;
    }
  }
  p:nth-child(2) {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 130%;
    @media (max-width: 768px) {
      font-size: 20px;
      width: 300px;
      text-align: center;
    }
  }
}
