.Rental_box {
  .text_width {
    p:nth-child(1) {
      text-transform: uppercase;
      font-size: 48px !important;
      margin-bottom: 18px !important;
      @media (max-width: 768px) {
        font-size: 40px !important;
      }
    }
    p:nth-child(2) {
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    p:nth-child(4) {
      color: #ffff;
    }
  }
  .choice_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    @media (max-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .choice_content {
      width: 1200px;
      color: #fff;
      text-align: center;
      padding: 80px 0;
      @media (max-width: 768px) {
        width: 100%;
        padding: 40px 0;
        text-align: left;
      }
      div:nth-child(1) {
        font-size: 48px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 24px;
        @media (max-width: 768px) {
          font-size: 40px;
        }
      }
      div:nth-child(2) {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 32px;
      }
      div:nth-child(3) {
        width: 100%;
        @media (max-width: 768px) {
          height: 131px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
