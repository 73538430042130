.investment_box {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  color: white;

  img {
    width: 100%;
    height: 100vh;
    display: block;
  }

  .investment_title_box {
    position: absolute;
    top: 50%;
    left: 28%;
    transform: translate(-50%, -50%);
    text-align: left;
    width: 600px;
    @media (max-width: 768px) {
      width: 90%;
      left: 50%;
    }
  }
  div:nth-child(1) {
    font-size: 64px;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
  div:nth-child(2) {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  div:nth-child(3) {
    border-radius: 4px;
    background: #ff5b10;
    // height: 60px;
    padding: 16px 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    width: 130px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .rental_box {
    height: 90vh;
    img {
      object-fit: cover;
      object-position: bottom;
    }
  }
}
