.custom-menu {
  .ant-dropdown-menu-item {
    line-height: 200%;
    color: #333;
    font-size: 20px !important;
    font-family: PTSerif;
  }

  .ant-dropdown-menu-item:hover {
    background-color: #f9bf30 !important;
  }
}
