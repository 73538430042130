.contact_box {
  padding: 60px;
  padding-top: 100px;
  padding-bottom: 0;
  margin-bottom: 60px;
  box-sizing: border-box;
  text-align: center;
  overflow-x: hidden;

  h1 {
    margin-bottom: 20px;
    font-size: 40px;
    color: #4b4b4b;
    margin-bottom: 60px;
    margin-top: 0;
  }

  p {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 48px;
    padding-left: 15%;
    padding-right: 15%;
  }

  div {
    .language_button {
      padding: 16px 24px;
      background-color: #f9bf2f;
      border: none;
      border-radius: 12px;
      color: black;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 24px;
      cursor: pointer;
    }
    .Chinese_button {
      margin-left: 48px;
    }
  }
}
@media (max-width: 768px) {
  .contact_box {
    padding: 24px;
    margin-bottom: 24px;
    text-align: left;

    h1 {
      font-size: 32px;
      color: #000000;
      margin-bottom: 24px;
    }

    p {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 32px;
      padding-left: 0%;
      padding-right: 0%;
    }

    .responsive-iframe {
      width: 100% !important;
    }
    div {
      .language_button {
        margin-bottom: 16px;
      }
      .Chinese_button {
        margin-left: 16px;
      }
    }
  }
}
