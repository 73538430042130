.logos_section {
  text-align: center;
  padding: 20px;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  h2 {
    color: #000;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 150%;
    margin: 0;
    margin-bottom: 56px;
    @media (max-width: 768px) {
      text-align: left;
      margin-bottom: 40px;
    }
  }

  .logos {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 56px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 12px;
      margin-bottom: unset;
    }

    .logo {
      width: 160px;
      height: 80px;
      position: relative;
      cursor: pointer;

      img {
        height: 100%;
        width: 100;
      }
    }
    .active-line {
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: red;
    }
  }
}
