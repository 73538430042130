.financial-investment {
  position: relative;
  color: white;
  padding: 108px 120px;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding: 40px 21px;
  }

  .background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .financial_content {
    position: relative;
    max-width: 460px;
    z-index: 2;

    h1 {
      font-size: 48px;
      font-weight: 700;
      line-height: 150%;
      text-transform: capitalize;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
    }
  }
}
