.car_banner {
  height: 100vh;
  background: url("../../../assets/img/car/New York.jpeg") no-repeat center
    center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    background-position-x: 37%;
  }
  p:nth-child(1) {
    text-align: center;
    width: 1135px;
    color: white;
    font-size: 112px;
    font-weight: 800;
    line-height: 130%;
    @media (max-width: 768px) {
      width: 100%;
      font-size: 40px;
      padding-left: 20%;
      padding-right: 20%;
    }
  }
}
