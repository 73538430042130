.service_box {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: unset;
    padding: 40px 20px;
  }
  .title {
    width: 100%;
    color: #000;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 150%;
    text-transform: capitalize;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      text-align: left;
    }
  }
  .desc {
    width: 1000px;
    margin: 0 auto;
    color: #525252;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    @media (max-width: 768px) {
      text-align: left;
      width: 100%;
      font-size: 14px;
    }
    p {
      margin-bottom: 16px;
    }
  }
  .about_us {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 80px;
    @media (max-width: 768px) {
      gap: 8px;
      margin-top: 24px;
    }
    img {
      flex: 1;
      width: 100%;
      max-width: calc(33.33% - 8px);
      object-fit: cover;
    }
  }
}
