.footer_box {
  display: flex;
  justify-content: space-between;
  background-color: #000000;
  box-sizing: border-box;
  color: #919191;
  padding: 100px 100px 52px 100px;

  .footer_content {
    img {
      width: 200px;
      padding-top: 10px;
      margin-right: 16px;
    }
  }
  div {
    margin-right: 16px;

    p {
      margin-bottom: 48px;
      font-size: 14px;
    }
  }
}

  @media (max-width: 768px) {
  .footer_box {
    display: flex;
    flex-direction: column;
    padding: 48px 24px 24px 24px;

    .footer_content {
      img {
        width: 200px;
        padding-top: 0px;
        margin-right: 0px;
      }
    }
    div {
      margin-right: 0px;

      p {
        margin-bottom: 24px;
        font-size: 14px;
      }
    }
  }
}
