.service-card {
  color: white;
  padding: 32px 24px;
  margin-bottom: 10px;
  background-color: #161616;

  .service-number {
    margin-bottom: 32px;
    font-size: 52px;
    font-weight: 700;
    line-height: 48px;
  }

  .service-title {
    font-weight: bold;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
    line-height: 130%;
  }

  .service-description {
    color: var(--Color-Main-White, #fff);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }
}
