.real_content {
  .text_width {
    color: #000;
    margin-right: unset;
    p:nth-child(1) {
      text-transform: uppercase;
      font-size: 48px !important;
      margin-bottom: 18px !important;

      @media (max-width: 768px) {
        font-size: 40px !important;
      }
    }
    p:nth-child(2) {
      color: #000;
      margin-bottom: 24px;
    }
  }
}
.realEstate_box {
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding: 40px 20px;
  }

  .content_box {
    width: 915px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
    }
    .title {
      color: #fff;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      text-transform: uppercase;
      margin-bottom: 16px;
      @media (max-width: 768px) {
        font-size: 40px;
      }
    }
    .desc {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-align: center;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 16px;
        text-align: left;
      }
    }
    .img_box {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
.img_box {
  height: unset !important;
}

.building_materials {
  background-color: #000;
  display: flex;
  justify-content: flex-end;
  .image_container {
    position: relative;
    width: 1091px;
    height: 640px;
    @media (max-width: 768px) {
      width: 100vw;
      height: 626px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      max-width: 580px;
      background-color: #fff;
      border: 1px solid var(--Neutral-colors-400, #e1e4ed);
      border-radius: 8px;
      padding: 40px 32px;
      position: absolute;
      top: 50%;
      left: -200px;
      transform: translateY(-50%);
      @media (max-width: 768px) {
        width: 80%;
        left: 4%;
        padding: 20px;
      }
      h1 {
        color: #000;
        font-size: 40px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
      }

      p {
        color: #525252;
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}
