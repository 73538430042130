.content {
  background-color: black;
  .text_width {
    color: #ffff;
    p:nth-child(1) {
      text-transform: uppercase;
      font-size: 48px !important;
      margin-bottom: 18px !important;
      @media (max-width: 768px) {
        font-size: 40px !important;
      }
    }
    p:nth-child(2) {
      color: #ffff;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    p:nth-child(4) {
      color: #ffff;
    }
  }
}
.last_box {
  background-color: black;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
  .img_left {
    width: 59%;
    height: 558px;
    @media (max-width: 768px) {
      width: 100%;
      height: 253px;
    }
    img {
      width: auto;
      height: 100%;
    }
  }
  .text_right {
    width: 41%;
    padding-right: 120px;
    @media (max-width: 768px) {
      width: 100%;
      padding-right: unset;
    }
    .text_box {
      background-color: #fff;
      padding: 32px;
      @media (max-width: 768px) {
        padding: 40px 20px 24px 20px;
      }
      p:nth-child(1) {
        color: #000;
        font-size: 48px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 24px;
        @media (max-width: 768px) {
          font-size: 40px;
        }
      }
      p:nth-child(2),
      p:nth-child(3) {
        color: #525252;
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 16px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
}
