.business_box {
  padding: 60px;
  padding-top: 30px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.services {
  margin-bottom: 0px !important;
}

.business_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 90px;

  .business_details {
    padding-left: 10vw;
    margin-right: 32px;
    @media (max-width: 1400px) {
      padding-left: 0;
    }

    .business_title {
      margin-bottom: 60px;

      h1 {
        font-size: 44px;
        font-weight: bold;
        color: #4b4b4b;
        margin: 0;
      }

      h2 {
        font-size: 18px;
        color: #000;
        margin: 0;
        font-weight: 200;
      }
    }

    .business_list {
      list-style: none;
      padding-left: 42px;

      li {
        font-size: 18px;
        line-height: 1.6;
        position: relative;
        padding-left: 20px;
        margin-bottom: 12px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
          background-color: #ffd700;
          border-radius: 50%;
        }
      }
    }
  }

  .business_images {
    display: flex;
    align-items: center;

    img {
      width: 380px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .business_group_images {
    img {
      width: 780px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.financial_Investment {
  position: relative;
  background-color: #f9bf30;
  text-align: center;
  padding: 20px;
  padding-bottom: 60px;
  box-sizing: border-box;
  margin-top: 32px;

  .financial_content {
    max-width: 50%;
    margin: 0 auto;
    @media (max-width: 1400px) {
      max-width: 90%;
    }

    h1 {
      font-size: 48px;
      margin-bottom: 2px;
      color: white;
    }

    p {
      font-size: 18px;
      margin-bottom: 32px;
    }

    .description {
      font-size: 16px;
      line-height: 180%;
    }
  }

  .image {
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 280px;
    img {
      width: 100%;
    }
  }
}
.learn-more-button {
  border: unset;
  background-color: #f9bf30;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 150px;
  padding: 2px;
  @media (max-width: 1400px) {
    margin-left: 50px;
  }

  .text {
    background-color: white;
    color: black;
    padding: 10px;
    font-weight: 300;
    font-size: 12px;
  }

  img {
    width: 50px;
    padding-left: 8px;
  }
}

.financial_button {
  background-color: white;
  margin-top: 32px;
  margin-left: 0px;
  .text {
    background-color: #f9bf30;
  }

  img {
    width: 50px;
    padding-left: 8px;
  }
}
@media (max-width: 768px) {
  .business_box {
    padding: 24px;
    padding-top: 30px;
  }
  .business_content {
    margin-top: 50px;
    margin-bottom: 50px;

    .business_details {
      padding-left: 0vw;
      margin-right: 4px;

      .business_title {
        margin-bottom: 32px;

        h1 {
          font-size: 32px;
          width: 120%;
        }

        h2 {
          font-size: 14px;
          width: 100%;
        }
      }

      .business_list {
        padding-left: 0px;

        li {
          font-size: 14px;
        }
      }
    }

    .business_images {
      img {
        width: 150px;
        margin-right: 0px;
        &:last-child {
          display: none;
        }
      }
    }

    .business_group_images {
      img {
        width: 780px;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .services {
    display: flex;
    flex-direction: column;
    .business_group_images {
      img {
        width: 100%;
        margin-top: 32px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .financial_Investment {
    padding: 16px 8px;
    margin-top: 32px;
    margin-bottom: 24px;

    .content {
      max-width: 95%;
      margin: 0 auto;

      h1 {
        font-size: 28px;
        margin-bottom: 2px;
      }

      p {
        font-size: 14px;
        margin-bottom: 32px;
      }

      .description {
        font-size: 16px;
        line-height: 180%;
      }
    }

    .image {
      position: unset;
      width: 70%;
      margin-top: 24px;
      img {
        width: 100%;
      }
    }
  }
}
