.home_box {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  img {
    width: 100%;
    height: 100vh;
    display: block;
  }

  .home_title_box {
    position: absolute;
    top: 50%;
    left: 28%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  .home_title {
    display: flex;
    align-items: center;
    font-size: 80px;
    font-weight: 800;
    margin-bottom: 56px;

    p:nth-child(2) {
      padding-left: 24px;
      padding-right: 24px;
      color: #ff5f59;
    }
  }
  .home_sol {
    text-transform: uppercase;
    font-size: 48px;
  }
}

@media (max-width: 768px) {
  .home_box {
    img {
      object-fit: cover;
      object-position: bottom;
      height: 90vh;
    }
    .home_title_box {
      top: 42%;
      left: 54%;
      width: 100%;
    }
    .home_title {
      font-size: 40px;
      margin-bottom: 56px;

      p:nth-child(2) {
        padding-left: 12px;
        padding-right: 12px;
        color: #ff5f59;
      }
    }
    .home_sol {
      text-align: left;
      font-size: 24px;
    }
  }
}
