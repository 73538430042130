.groupBrands_box {
  padding: 60px;
  padding-top: 25px;
  margin-top: 30px;
  box-sizing: border-box;
  overflow-x: hidden;
  .groupBrands_content {
    text-align: center;
    font-size: 26px;
    margin-bottom: 40px;
    margin-top: 30px;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    height: auto;
    line-height: 0;

    img {
      width: 20vw !important;
      height: auto;
      margin: auto;
    }
  }
  .ant-carousel .slick-dots li button {
    background: black !important;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: black !important;
  }
  .ant-carousel .slick-dots {
    bottom: -25px !important;
  }
}

@media (max-width: 768px) {
  .groupBrands_box {
    padding: 24px;
    margin-top: 30px;
    margin-bottom: 40px;
    height: auto;
    .groupBrands_content {
      text-align: left;
      margin-top: 16px;
      font-size: 16px;
      margin-bottom: 24px;
      line-height: 200%;
    }
    .ant-carousel .slick-slide {
      img {
        width: 42vw !important;
      }
    }
  }
}
