.aboutUs_box {
  padding: 60px;
  padding-top: 30px;
  padding-bottom: 0px;
  box-sizing: border-box;
  overflow-x: hidden;

  .aboutUs_content {
    text-align: center;
    width: 90%;
    margin: 0 auto;

    .aboutUs_logo {
      width: 230px;
      height: 96px;
      margin-bottom: 18px;
      margin-top: 42px;
    }
    p {
      font-size: 26px;
      margin-bottom: 24px;
    }
    .aboutUs_country {
      width: 100%;
      margin-top: 40px;
    }
  }
  .aboutUs_video {
    text-align: center;
    video {
      width: 50%;
    }
  }
  .aboutUs_img {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
@media (max-width: 768px) {
  .aboutUs_box {
    padding: 24px;
    padding-bottom: 0px;

    .aboutUs_content {
      text-align: left;
      width: 100%;

      .aboutUs_logo {
        width: 120px;
        height: auto;
        margin-bottom: 12px;
        margin-top: 24px;
      }
      p {
        font-size: 16px;
        margin-bottom: 16px;
        line-height: 150%;
      }
      .aboutUs_country {
        width: 100%;
        margin-top: 16px;
      }
    }
    .aboutUs_video {
      video {
        width: 100%;
      }
    }
  }
}
