body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: PTSerif;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p {
  margin: 0;
}
@font-face {
  font-family: "PTSerif";
  font-weight: 400;
  font-style: normal;
  src: local("PTSerif"),
    url("assets/font/PTSerif-Regular.ttf") format("opentype");
}
/* For Firefox */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* For Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  display: none;
}
