.news_box {
  padding: 60px;
  padding-top: 40px;
  margin-bottom: 160px;
  .news_content {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    div {
      width: 32%;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
        margin-bottom: 24px;
      }
      p:nth-child(2) {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 32px;
      }
      p:nth-child(3) {
        font-weight: 300;
        font-size: 16px;
        padding-right: 30px;
      }
    }
  }
}
@media (max-width: 768px) {
  .news_box {
    padding: 24px;
    padding-top: 40px;
    margin-bottom: 0px;
    .news_content {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      div {
        width: 100%;
        height: auto;
        margin-bottom: 40px;
        img {
          width: 100%;
          height: 100%;
          margin-bottom: 18px;
        }
        p:nth-child(2) {
          margin-bottom: 16px;
        }
        p:nth-child(3) {
          padding-right: 0px;
        }
      }
    }
  }
}
