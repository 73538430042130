.automotive-solutions {
  padding: 0 120px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 40px 20px;
  }

  h1 {
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 150%;
    text-transform: capitalize;
    margin-top: 80px;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      margin-bottom: 40px;
      text-align: left;
    }
  }
}
