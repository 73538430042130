.card-model {
  display: flex;
  gap: 20px;
  padding: 20px;
  .card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    transition: transform 0.3s ease;
    margin-bottom: 32px;

    &:hover {
      transform: scale(1.03);
    }

    .card-image {
      position: relative;

      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
      }

      .card-price {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        font-weight: 700;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }

    .card-content {
      padding: 16px;
      flex-grow: 1;

      .card-title {
        font-size: 18px;
        font-weight: 700;
        color: #333;
        margin-bottom: 8px;
      }

      .card-details {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 8px 0;

        .icon {
          font-size: 16px;
          color: #ff6600;
        }

        .text {
          font-size: 14px;
          color: #666;
        }
      }
    }

    .card-footer {
      display: flex;
      justify-content: center;
      padding: 16px;
      border-top: 1px solid #eee;

      button {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 700;
        background: #ff6600;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #e65c00;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .card-model {
    flex-direction: column;
  }
}
