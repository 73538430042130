.pro_business_box {
  background: url("../../../assets/img/ourProject/business.png") no-repeat
    center center;
  background-size: cover;
  background-color: black;
  padding: 80px 120px;
  @media (max-width: 768px) {
    padding: 40px 21px;
  }

  .business_title {
    color: #fff;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 150%;
    text-transform: capitalize;
    margin-bottom: 32px;
    @media (max-width: 768px) {
      text-align: left;
    }
  }
  .business_container {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 20px;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
}
